import React, { Component } from 'react'

class Footer extends Component{
    render(){
        return(
            <div>
                <footer className="footer-setion">
                    <div className="footer-top pt-5 pb-5">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <img src="img/logo-color-1x.png" alt="footer logo" width="180" className="img-fluid mb-3" />
                                        <p>Nous accompagnons les entreprises, startups et entrepreneurs dans la réaslions de leurs projets webs & mobiles (Cration site web, Applications mobiles & Desktops,Logiciels...) et de Marketing Digital</p>

                                        <ul className="list-inline">
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Privacy
                                                policy</a></li>
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Terms
                                                and Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Localisation</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>Adresse:</strong>
                                                Dakar, Senegal
                                                <br />
                                            </li>
                                        <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>N° tel:</strong> 
                                                    +221 76 661 82 53</li>
                                            <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>Email:</strong><a
                                                    href="mailto:mail@example.com"> blackelitedev@bed.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Liens</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="#about">A Propos</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="#services">Nos service</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="#pricing">Nos tarifs</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="#contact">Contactez-nous</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom gray-light-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center text-md-left align-items-center">
                                <div className="col-md-6 col-lg-5"><p className="text-md-left copyright-text pb-0 mb-0">Copyrights © 2023. All
                                    rights reserved by
                                    <a href="/#"> Black Elite Agency</a></p></div>
                                <div className="col-md-6 col-lg-7">
                                    <ul className="social-list list-inline list-unstyled text-md-right">
                                        <li className="list-inline-item"><a href="/#" target="_blank" title="Facebook"><span
                                                className="ti-facebook"></span></a></li>
                                        <li className="list-inline-item"><a href="/#" target="_blank" title="Twitter"><span
                                                className="ti-twitter"></span></a></li>
                                        <li className="list-inline-item"><a href="/#" target="_blank"
                                                                        title="Instagram"><span className="ti-instagram"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;