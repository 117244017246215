import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top custom-nav white-bg">
            <div className="container">
              <a className="navbar-brand" href="index-2.html">
                <img
                  src="img/logo-color-1x.png"
                  width="180"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link page-scroll"
                      href="#about"
                    >
                      Accueil
                    </a>
                
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#about">
                      A Propos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#services">
                      Services
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="#pricing">
                      Blog
                    </a>
                  </li> */}
                  <li className="nav-item dropdown">
                   
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
               
                  
            </div>
            <div className="devis-btn">
            <a className="custom-btn rounded btn-primary btn-lg unstyled about-content-right  mr-5 d-block" href = "#contact">   
                 Demander un Devis
               </a> 
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
