import React from "react";
import _data from "../../data";

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    _data.promo3.title = _data.promo.title;
    _data.promo3.description = _data.promo.description;
    this.setState({
      promo: _data.promo3
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section pt-5 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading text-center mb-5">
                  <h2>{this.state.promo.title}</h2>
                  <p className="lead">{this.state.promo.description}</p>
                </div>
              </div>
            </div>

            <div className="row no-gutters">
              {(this.state.promo.items || []).map((item, index) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12"
                    key={item.title}
                  >
                    <div className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                      <span className={item.icon}></span>
                      <div className="feature-content">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Promo;
