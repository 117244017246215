module.exports = {
  promo: {
    title: "Pourquoi choisir Black Elite Agency?",
    description:
      "Nous accompagnons Entreprise, Startups, Entrepreneurs...dans la réalisation de leurs projets de webs & mobiles",
    items: [
      {
        title: "Keyword Rankings",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s using Social Proof & Urgency for your business.",
        image: "img/service6.jpg"
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency. Realize importance of social proof in customer’s purchase decision.",
        image: "img/service2.jpg"
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics of your website.",
        image: "img/service7.jpg"
      }
    ]
  },
  promo2: {
    items: [
      {
        title: "Une Expertise Technique Avancée",
        description:
          "Black Elite Agency se distingue par son équipe de développeurs hautement qualifiés, maîtrisant les technologies les plus avancées pour garantir des solutions web innovantes et performantes.",
        image: "img/promo-1.png",
        image8: "img/service6.jpg"
      },
      {
        title: "La Qualité,...notre Credo!",
        description:
          "Nous sommes déterminés à fournir des produits de la plus haute qualité. Chaque projet est soumis à des normes rigoureuses de contrôle de la qualité pour assurer la satisfaction du client et la fiabilité à long terme.",
        image: "img/promo-2.png",
        image8: "img/service2.jpg"
      },
      {
        title: "Une Approche Collaborativ",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        image: "img/promo-3.png",
        image8: "img/service7.jpg"
      }
    ]
  },
  promo3: {
    items: [
      {
        title: "Une Expertise Technique Avancée",
        description:
          "Black Elite Agency se distingue par son équipe de développeurs hautement qualifiés, maîtrisant les technologies les plus avancées pour garantir des solutions web innovantes et performantes.",
        icon: "ti-bar-chart icon-color-1 icon rounded"
      },
      {
        title: "La Qualité,...notre Credo!",
        description:
          "Nous sommes déterminés à fournir des produits de la plus haute qualité. Chaque projet est soumis à des normes rigoureuses de contrôle de la qualité pour assurer la satisfaction du client et la fiabilité à long terme.",
        icon: "ti-shield icon-color-2 icon rounded"
      },
      {
        title: "Une Approche Collaborative",
        description:
          "Notre approche centrée sur le client garantit une compréhension approfondie des besoins spécifiques de chaque projet, assurant ainsi des solutions web adaptées et personnalisées.",
        icon: "ti-cup icon-color-3 icon rounded"
      }
    ]
  },
  promo6: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Proactively provide access to distinctive quality vectors for adaptive solutions.",
        image: "ti-bar-chart icon-md icon color-primary"
      },
      {
        title: "Increase store trust",
        description:
          "Interactively reintermediate reliable platforms rather than synergistic e-commerce.",
        image: "ti-headphone-alt icon-md icon color-primary"
      },
      {
        title: "Growth guaranteed",
        description:
          "Professionally pursue standards compliant networks whereas impactful experiences.",
        image: "ti-vector icon-md icon color-primary"
      }
    ]
  },
  promo7: {
    items: [
      {
        title: "Keyword Rankings",
        description: "Increase sales by showing true dynamics of your website.",
        image: "ti-bar-chart text-white"
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency.",
        image: "ti-vector text-white"
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision.",
        image: "ti-stats-up text-white"
      }
    ]
  },
  aboutUs: {
    title: "A Propos",
    description:
      "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Increase conversion",
        description: "Display recent conversions, build credibility and trust.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Product analytics",
        description: "A top promo bar that counts down until a few discounts.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ],
    counts: [
      {
        title: "Active users",
        count: 1600,
        icon: "ti-medall-alt color-primary",
        boxClass: "single-card box-animation-1 secondary-bg text-white",
        boxClass5:
          "single-card box-animation-1 counter-card secondary-bg text-white"
      },
      {
        title: "Global client",
        count: 2500,
        icon: "ti-headphone-alt",
        boxClass: "single-card box-animation-2 icon-color-1",
        boxClass5: "single-card box-animation-2 counter-card icon-color-1"
      },
      {
        title: "Win award",
        count: 2150,
        icon: "ti-cup",
        boxClass: "single-card box-animation-3 icon-color-2",
        boxClass5: "single-card box-animation-3 counter-card icon-color-2"
      },
      {
        title: "Clients satisfaction",
        count: 2150,
        icon: "ti-user color-secondary",
        boxClass: "single-card box-animation-4 primary-bg text-white",
        boxClass5:
          "single-card box-animation-4 counter-card primary-bg text-white"
      }
    ]
  },
  aboutUs2: {
    title: "BLACK ELITE AGENCY...        une agence de Développement Web & Mobile et de Marketing digitale",
    description:
      "Découvrez l'essence du développement web & mobile et du marketing digital avec Black Elite Agency, où l'innovation, l'expertise et la passion se réunissent pour façonner des solutions numériques exceptionnelles.",
    image: "img/hero-bg-5.jpg",
    items: [
      {
        title: "Creativité",
        description: "Nous  vous proposons des solutions webs innovantes, adaptées, sécurisées...",
        longDescription:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources. Continually pontificate stand-alone benefits whereas.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Efficience ",
        description: "Nous donnons vie a  vos idées, avec le meilleur rapport Qualité-Prix.",
        longDescription:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ]
  },
  aboutUs6: {
    aboutImage: "img/business.png",
    items: [
      {
        title: "Discussion for Idea",
        description:
          "Enthusiastically coordinate competitive users vis-a-vis results.",
        longDescription: "",
        icon: "ti-light-bulb rounded mr-3 icon icon-color-3"
      },
      {
        title: "Perfect testing",
        description:
          "Credibly evisculate covalent quality vectors and maintainable.",
        longDescription: "",
        icon: "ti-paint-bucket rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service: {
    title: "Happy to Assist You",
    description:
      "Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-1"
      },
      {
        title: "Social media marketing",
        description:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-2"
      }
    ]
  },
  service2: {
    title: "Black Elite Agency vous propose les meilleures Solutions Webs & Mobiles",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Web design and development",
        description:
          "Creation de sites webs (Site vitrine, Landing page, E-commerce), Gestion Backend, Application Desktops",
        icon: "ti-vector icon-color-4 icon rounded",
        icon6: "ti-vector icon-md color-secondary",
        image: "img/service7.jpg"
      },
      {
        title: "Mobile app development",
        description:
          "Applications mobiles securisées, fiables et trés bien affinées coté Design et Performance",
        icon: "ti-mobile icon-color-5 icon rounded",
        icon6: "ti-mobile icon-md color-secondary",
        image: "img/service5.jpg"
      },
      {
        title: "Optimisation des Moteurs de Recherche (SEO)",
        description:
          " Optimisation du contenu et de la structure d'un site web pour améliorer son classement dans les résultats des moteurs de recherche, augmentant ainsi la visibilité organique.",
        icon: "ti-bar-chart icon-color-1 icon rounded",
        icon6: "ti-bar-chart icon-md color-secondary",
        image: "img/service1.jpg"
      },
      {
        title: "Community Management ",
        description:
          "Gestion et promotion de la présence de la marque sur les réseaux sociaux tels que Facebook, Twitter, Instagram, LinkedIn, etc.",
        icon: "ti-layout-media-left-alt icon-color-2 icon rounded",
        icon6: "ti-layout-media-left-alt icon-md color-secondary",
        image: "img/service3.jpg"
      },
      {
        title: "Email marketing",
        description:
          "Utilisation ciblée de campagnes d'email pour communiquer avec les clients potentiels ou existants, généralement pour promouvoir des offres, des produits ou des événements.",
        icon: "ti-email icon-color-3 icon rounded",
        icon6: "ti-email icon-md color-secondary",
        image: "img/service2.jpg"
      },
      
      {
        title: "affiliate marketing",
        description:
          " Collaboration avec des partenaires pour promouvoir des produits ou des services, généralement en échange d'une commission sur les ventes générées.",
        icon: "ti-announcement icon-color-6 icon rounded",
        icon6: "ti-announcement icon-md color-secondary",
        image: "img/service6.jpg"
      }
    ]
  },
  service4: {
    title: "Black Elite Agency vous propose les meilleures Solutions Webs & Mobiles",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Web & Mobile Dev",
        description:
          "Creation de sites webs (Site vitrine, Landing page, E-commerce), Gestion Backend, Application Desktops.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Marketing Digital",
        description:
          "Applications mobiles securisées, fiables et trés bien affinées coté Design et Performance.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ]
  },
  service5: {
    title: "We Are Happy to Assist You all Time MomentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services).",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Social media marketing",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ],
    items2: [
      {
        title: "Email marketing",
        description:
          "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies.",
        icon: "ti-email rounded mr-3 icon icon-color-3"
      },
      {
        title: "Web Development",
        description:
          "Your website provides a place for interested users to learn more about your business, your products and services.",
        icon: "ti-vector rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service6: {
    title: "Market your business to own your market",
    description:
      "We provide professional services for grow your business and increases sell digital product"
  },
  serviceAboutUs: {
    title: "We are Agency, We Create Solutions",
    description:
      "We provide professional services for grow your business and increases sell digital product",
    items: [
      {
        title: "Our Mission",
        description: "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        image: "img/mission.jpg"
      },
      {
        title: "Our Vision",
        description: "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements",
        image: "img/vision.jpg"
      },
      {
        title: "Why Us?",
        description: "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies",
        image: "img/grow.jpg"
      },
    ]  
  },
  price: {
    title: "Nos Services",
    description:
      "Selon vos besoins, votre cahiers de charge, nous proposons une Tarification adaptée avec un parfait rapport Qualité-Prix. ",
    packages: [
      {
        price: "WEB DEV",
        license: "Creation de Site",
        isFeatured: false,
        features: [
          "Site vritine",
          "Landing Page",
          "Site E-commerce",
          "Responsivité",
          "Sécurité",
        
          
        ]
      },
      {
        price: "APP DEV",
        license: "Mobile & Windows",
        isFeatured: true,
        features: [
          "Application mobile",
          "Application Android",
          "Desktop APP",
          "Optimisation",
          "Responsivité",
          "Sécurité",
          
        ]
      },
      {
        price: "Marketing Digital",
        license: "Refonte de site",
        isFeatured: false,
        features: [
          "Optimisation des Moteurs de Recherche (SEO)",
          "Elaboration de stratégie",
          "Community management",
          "Retargeting",
          "Publicités",
          "Web Analytic",
          
        ]
      }
    ]
  },
  testimonial: {
    title: "Ce que disent les client de nous",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },

      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Contactez_nous",
    description:
      "Remplir le formulaire pour entrer en contact avec notre équipe à temps réel; Parlez-nous de vos projets, nous vous proposons les meilleures solutions.",

    office: "Notre Siège",
    address: "..., Dakar, Sénégal",
    phone: "+221 77 8376 6284",
    email: "hello@Blackelitedev.com"
  },
  hero: {
    title: "Bienvenue Chez The Builder Studio ! We are Digital Agency & Marketing",
    description:
      " Nous donnons vie à idées!",
    
    
    
      bgImage: "img/hero-bg-2.jpg",
    animations: [
      {
        className: "img-fluid d-block animation-one",
        src: "img/hero-animation-04.png",
        width: ""
      },
      {
        className: "img-fluid d-none d-lg-block animation-two",
        src: "img/hero-animation-01.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-three",
        src: "img/hero-animation-02.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-four",
        src: "img/hero-animation-03.svg",
        width: "230"
      }
    ]
  },
  //templATe 3
  hero2: {
   
      title: " ",
      
    description:
      "Transformez votre vision en réalité avec Black Elite Agency, l'architecte numérique de vos rêves.",
    bgImage: "img/hero-bg-1.jpg",
    counters: [
      {
        name: "Happy Client",
        count: 2350
      },
      {
        name: "App Download",
        count: 2150
      },
      {
        name: "Total Rates",
        count: 2350
      },
      {
        name: "Awards win",
        count: 2250
      }
    ]
  },
  hero3: {
    bgImage: "img/curve-bg.jpg",
    images: {
      img1: "img/hero-animation-01.svg",
      img2: "img/hero-animation-02.svg",
      img3: "img/hero-animation-03.svg",
      img4: "img/hero-animation-04.svg",
      img5: "img/bgweb"
    },
  },
  hero4: {
    title: "Build anything you want",
    description:
      "We offer Digital Marketing / SEO in Kalawad Road Rajkot, Gujarat. Find here details about our company",
    bgImage: "img/hero-bg-1.jpg"
  },
  hero5: {
    title: "Build anything you want",
    description:
      "Progressively monetize cost effective innovation vis-a-vis cross-unit infrastructures. Uniquely e-enable front-end core competencies vis-a-vis premier",
    bgImage: "img/hero-bg-4.jpg"
  },
  hero7: {
    title: "We are Digital Agency & Marketing",
    description:
      "Professionally simplify integrated partnerships via enterprise-wide leadership skills. Appropriately streamline cross functional infomediaries without resource maximizing internal",
    bgImage: "img/hero-bg-1.jpg"
  },
  heroAboutUs: {
    title: "A propos Black Elite Agency",
    bgImage: "img/hero-bg-1.jpg"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      "img/team-1.jpg",
      "img/team-2.jpg",
      "img/team-3.jpg",
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
